import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_LOGIN_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAIL,
  USER_UPDATE_PROFILE_REQUEST,
  USER_UPDATE_PROFILE_SUCCESS,
  USER_UPDATE_PROFILE_FAIL,
  ADD_DISH_OF_USER_REQUEST,
  ADD_DISH_OF_USER_SUCCESS,
  ADD_DISH_OF_USER_FAIL,
  DELETE_DISH_OF_USER_SUCCESS,
  RESET_SUCCESS,
  SET_DISHES_OF_USER,
} from '../constants/userConstants'

export const userLoginReducer = (state = {}, action) => {
  const { type, payload } = action

  switch (type) {
    case USER_LOGIN_REQUEST:
      return { loading: true }
    case USER_LOGIN_SUCCESS:
      console.log(action.payload)
      return { loading: false, userInfo: action.payload }
    case USER_LOGIN_FAIL:
      console.log(payload)
      return { loading: false, error: action.payload }
    case USER_LOGOUT:
      return {}
    default:
      return state
  }
}

export const userRegisterReducer = (state = {}, action) => {
  const { type, payload } = action

  switch (type) {
    case USER_REGISTER_REQUEST:
      return { loading: true }
    case USER_REGISTER_SUCCESS:
      return { loading: false, userInfo: action.payload }
    case USER_REGISTER_FAIL:
      console.log(action.payload)
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const userProfileUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_UPDATE_PROFILE_REQUEST:
      return { loading: true }
    case USER_UPDATE_PROFILE_SUCCESS:
      return { loading: false, success: true, userInfo: action.payload }
    case USER_UPDATE_PROFILE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

const initialStateDishes = {
  userDishes: [],
}

export const userLogoutReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_LOGOUT:
      return [] // Reset the state to an empty object
    default:
      return state
  }
}

export const userDishesReducer = (state = initialStateDishes, action) => {
  switch (action.type) {
    case ADD_DISH_OF_USER_REQUEST:
      return { ...state, loading: true, error: null }
    case ADD_DISH_OF_USER_SUCCESS:
      console.log(action.payload)
      return {
        ...state,
        success: true,
        loading: false,
        dishes: action.payload,
        error: null,
      }
    case SET_DISHES_OF_USER:
      return {
        ...state,
        dishes: action.payload,
      }

    case RESET_SUCCESS:
      return {
        ...state,
        success: false,
      }

    case DELETE_DISH_OF_USER_SUCCESS:
      return {
        ...state,
        deleteSuccess: true,
        dishes: action.payload,
      }

    case ADD_DISH_OF_USER_FAIL:
      return { ...state, loading: false, error: action.payload }
    default:
      return state
    case USER_LOGOUT:
      return initialStateDishes
  }
}
