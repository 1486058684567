import { useState } from 'react'
import { useEffect } from 'react'
import { CircularProgressbar } from 'react-circular-progressbar'
import { buildStyles } from 'react-circular-progressbar'
import { useSelector, useDispatch } from 'react-redux'
import { GiFlame } from 'react-icons/gi'
import { GiWheat } from 'react-icons/gi'
import { GiChickenLeg } from 'react-icons/gi'
import { TbMeat } from 'react-icons/tb'
import { FaNutritionix } from 'react-icons/fa'
import { BiMessageSquareAdd } from 'react-icons/bi'
import { BiDish } from 'react-icons/bi'
import AddDishPage from '../pages/AddDishPage'
import DishesDisplayPage from '../pages/DishesDisplayPage'
import { checkServerStatus } from '../actions/serverActions'
import 'react-circular-progressbar/dist/styles.css'

const CaloriesOfDay = ({ data }) => {
  const dispatch = useDispatch()

  const [isAddDish, setisAddDish] = useState(false)
  const [isShowDish, setisShowDish] = useState(false)
  const { calories, carbohydrates, fat, fiber, protein } = data[0] || {
    calories: null,
    carbohydrates: null,
    fat: null,
    fiber: null,
    protein: null,
  }
  const [targetCalories, setTargetCalories] = useState(0)
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  // Calculate the percentage of consumed calories relative to the target calories
  const caloriesPercentage = calories
    ? Math.floor((calories / targetCalories) * 100)
    : 0

  useEffect(() => {
    if (userInfo) {
      // console.log(userInfo.targetCaloriesPerDay)
      setTargetCalories(userInfo.targetCaloriesPerDay)
    }
  }, [userInfo])

  useEffect(() => {}, [])

  return (
    <div className=' mx-auto w-full mt-6'>
      <div className='  mx-auto  w-3/4 p-4 rounded-3xl border-black '>
        {!isAddDish && (
          <>
            <div className='w-[100px] h-[100px] mx-auto filter  '>
              <CircularProgressbar
                value={caloriesPercentage}
                text={`${caloriesPercentage}%`}
                className='bg-black rounded-full p-2'
                styles={buildStyles({
                  backgroundColor: 'black',
                  textColor: 'green',
                  pathColor: 'green',
                  trailColor: 'transparent',
                })}
              />
            </div>
            {!isShowDish && (
              <div className=' w-full mx-auto bg-[#228B22] mt-4 flex  rounded-2xl justify-between p-2 text-white   '>
                <button
                  className='flex border-l-white border-l rounded-r-2xl hover:border-2 hover:border-white  w-1/2'
                  onClick={() => {
                    setisAddDish(true)
                    dispatch(checkServerStatus())
                  }}
                >
                  <BiMessageSquareAdd className='mt-1  mr-2' />
                  <h1 className=' mx-2 '> הוספת מנה</h1>
                </button>
                <button
                  className='flex justify-end rounded-l-2xl hover:border-2 hover:border-white  w-1/2'
                  onClick={() => setisShowDish(true)}
                >
                  <h1 className=' mx-2 '> צפייה במנות</h1>
                  <BiDish className='mt-1 ml-2' />
                </button>
              </div>
            )}
          </>
        )}
      </div>

      {!isAddDish && !isShowDish && (
        <div className='grid grid-cols-1  md:grid-cols-2  mx-auto  mt-3  w-4/6 my-auto  font-arvo font-medium gap-2  '>
          <div className='w-full flex justify-between ml-2 bg-white border border-black rounded-br-xl rounded-tl-xl '>
            <div className=' w-[120px] bg-black flex rounded-br-xl p-2 border-l border-black '>
              <GiFlame className=' mx-2  mt-1 text-white ' />
              <h1 className=' text-lg text-white'>קלוריות</h1>
            </div>
            <div className=' flex justify-center items-center w-3/5'>
              {targetCalories ? (
                <h1 className=''>
                  {targetCalories} / {calories}
                </h1>
              ) : (
                <h1 className=''>0</h1>
              )}
            </div>
          </div>
          <div className='w-full flex justify-between ml-2 bg-white border border-black rounded-br-xl rounded-tl-xl '>
            <div className=' w-[120px] bg-black flex rounded-br-xl p-2 border-l border-black '>
              <GiChickenLeg className=' mx-2  mt-1  text-white' />
              <h1 className=' text-lg text-white'>חלבון</h1>
            </div>
            <div className=' flex justify-center items-center w-3/5'>
              {protein ? (
                <h1 className=''> {protein}</h1>
              ) : (
                <h1 className=''>0</h1>
              )}{' '}
            </div>
          </div>
          <div className='w-full flex justify-between ml-2 bg-white border border-black rounded-br-xl rounded-tl-xl '>
            <div className=' w-[120px]  bg-black flex rounded-br-xl p-2  border-l border-black '>
              <GiWheat className=' mx-2  mt-1 text-white' />
              <h1 className=' text-lg text-white'>פחמימות</h1>
            </div>
            <div className=' flex justify-center items-center w-3/5'>
              {carbohydrates ? (
                <h1 className=''> {carbohydrates}</h1>
              ) : (
                <h1 className=''>0</h1>
              )}{' '}
            </div>
          </div>

          <div className='w-full flex justify-between ml-2 bg-white border border-black rounded-br-xl rounded-tl-xl '>
            <div className=' w-[120px] bg-black flex rounded-br-xl p-2 border-l border-black '>
              <TbMeat className=' mx-2  mt-1 text-white ' />
              <h1 className='text-white text-lg'>שומן</h1>
            </div>
            <div className=' flex justify-center items-center w-3/5'>
              {calories ? (
                <h1 className=''> {fat}</h1>
              ) : (
                <h1 className=''>0</h1>
              )}{' '}
            </div>
          </div>
          <div className='w-full flex justify-between ml-2 bg-white border border-black rounded-br-xl rounded-tl-xl '>
            <div className=' w-[120px] bg-black flex rounded-br-xl p-2 border-l border-black '>
              <FaNutritionix className=' mx-2  mt-1 text-white' />
              <h1 className=' text-lg text-white'>סיבים</h1>
            </div>
            <div className=' flex justify-center items-center w-3/5'>
              {fiber ? <h1 className=''> {fiber}</h1> : <h1 className=''>0</h1>}{' '}
            </div>
          </div>
        </div>
      )}
      {isAddDish && <AddDishPage />}
      {isShowDish && <DishesDisplayPage />}
    </div>
  )
}

export default CaloriesOfDay
