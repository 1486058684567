import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addDishOfUser } from '../actions/userActions'
import { resetSuccess } from '../actions/userActions'
import Navbar from '../components/Navbar'

const AddUserDish = () => {
  const dispatch = useDispatch()

  const [dishName, setDishName] = useState('')
  const [calories, setCalories] = useState(0)
  const [carbohydrates_total_g, setCarbohydrates_total_g] = useState(0)
  const [protein_g, setProtein] = useState(0)
  const [fat_total_g, setFat] = useState(0)
  const [fiber_g, setFiber_g] = useState(0)

  const [dishNameError, setDishNameError] = useState(false)
  const [caloriesError, setCaloriesError] = useState(false)

  const userDishes = useSelector((state) => state.userDishes)
  const { success } = userDishes
  useEffect(() => {
    if (success) {
      setTimeout(() => {
        dispatch(resetSuccess())
      }, 3000)
    }
  }, [success])

  const submitHandler = (e) => {
    e.preventDefault()
    if (dishName === '') {
      setDishNameError(true)
    } else {
      setDishNameError(false)
    }
    if (calories === 0) {
      setCaloriesError(true)
    } else {
      setCaloriesError(false)
    }
    // If there are no errors, dispatch the action to add the dish to the user's array of dishes
    if (dishName !== '' && calories !== 0) {
      // Dispatch action to add the dish to the user's array of dishes
      dispatch(
        addDishOfUser({
          dishName,
          calories,
          carbohydrates_total_g,
          protein_g,
          fat_total_g,
          fiber_g,
        })
      )
    }
    // Reset input fields
    setDishName('')
    setCalories(0)
    setCarbohydrates_total_g(0)
    setProtein(0)
    setFat(0)
    setFiber_g(0)
  }

  return (
    <>
      <Navbar />
      <div className='w-3/4 mx-auto max-w-md p-4 flex justify-center bg-white border border-black rounded-lg shadow sm:p-8  mt-10'>
        <h2 className='text-xl font-bold text-gray-900 '>
          הוספת מנה למנות שלי
        </h2>
      </div>

      <div className='w-3/4 mx-auto max-w-md p-4 bg-white border border-black rounded-lg shadow sm:p-8  mt-2'>
        <form onSubmit={submitHandler}>
          <div>
            <label className={`${dishNameError && 'text-red-500 '}`}>
              שם המנה :{' '}
            </label>

            <input
              className={` focus:ring-myGreen focus:border-myGreen bg-gray-200 flex-1 block w-full rounded-none 
               sm:text-sm border-gray-300`}
              type='text'
              value={dishName}
              onChange={(e) => setDishName(e.target.value)}
            />
            {dishNameError && <div className=' w-full h-1 bg-red-700'> </div>}
          </div>

          <div>
            <label className={`${caloriesError && 'text-red-500 '}`}>
              קלוריות :{' '}
            </label>

            <input
              className='focus:ring-myGreen focus:border-myGreen bg-gray-200 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300'
              type='number'
              value={calories}
              onChange={(e) => setCalories(e.target.value)}
            />
            {caloriesError && <div className=' w-full h-1 bg-red-700'> </div>}
          </div>

          <div>
            <label> חלבון : </label>
            <input
              className=' bg-gray-200 flex-1 block w-full  sm:text-sm border-gray-300'
              type='number'
              value={protein_g}
              onChange={(e) => setProtein(e.target.value)}
            />
          </div>

          <div>
            <label> שומן : </label>
            <input
              className=' bg-gray-200 flex-1 block w-full  sm:text-sm border-gray-300'
              type='number'
              value={fat_total_g}
              onChange={(e) => setFat(e.target.value)}
            />
          </div>
          <div>
            <label> פחמימות : </label>
            <input
              className=' bg-gray-200 flex-1 block w-full sm:text-sm border-gray-300'
              type='number'
              value={carbohydrates_total_g}
              onChange={(e) => setCarbohydrates_total_g(e.target.value)}
            />
          </div>
          <div>
            <label> סיבים : </label>
            <input
              className=' bg-gray-200 flex-1 block w-full  sm:text-sm border-gray-300 '
              type='number'
              value={fiber_g}
              onChange={(e) => setFiber_g(e.target.value)}
            />
          </div>

          <div className=' mt-3'>
            <button
              className='bg-black border border-black text-myGreen hover:bg-myGreen hover:text-black  font-semibold py-2 w-full rounded-xl'
              type='submit'
            >
              הוספת המנה
            </button>
          </div>
        </form>

        {dishNameError && (
          <div className=' bg-red-700 mt-1 rounded-t-lg py-1'>
            <h1 className=' text-white mr-2'>נא להכניס שם מנה</h1>
          </div>
        )}
        {caloriesError && (
          <div className=' bg-red-700 mt-1 rounded-b-lg py-1'>
            <h1 className=' text-white mr-2'>נא להכניס ערך קלורי של המנה</h1>
          </div>
        )}
        {success && (
          <div className='text-white h-12 bg-myGreen flex  justify-center rounded-xl  mt-3 '>
            <h1 className=' mt-2'> מנה זו הוספה בהצלחה</h1>
          </div>
        )}
      </div>
    </>
  )
}

export default AddUserDish
