import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { translateText } from "../apis/translateApi";
import { searchFood } from "../apis/dishesApi";
import { addDishFunction } from "../actions/caloriesActions";
import FoodItem from "../components/FoodItem";
import UserDishes from "../components/UserDishes";
import { BiMessageSquareAdd } from "react-icons/bi";
import { Link } from "react-router-dom";

const AddDishPage = () => {
  const [query, setQuery] = useState("");
  const [foods, setFoods] = useState([]);
  const [grams, setGrams] = useState();
  const [msgShow, setMsgShow] = useState(false);
  const [error, setError] = useState(false);
  const [errorGrams, setErrorGrams] = useState(false);

  const dispatch = useDispatch();
  const baseUrl = window.location.origin;

  const userInfoString = localStorage.getItem("userInfo");
  let userId;

  if (userInfoString) {
    const userInfo = JSON.parse(userInfoString);
    userId = userInfo._id;
  }

  const handleSearch = async () => {
    const translatedText = await translateText(query);
    if (translatedText) {
      const food = await searchFood(translatedText);
      // if (food.length == 0) {
      //   setError(true)
      //   setTimeout(() => {
      //     setError(false)
      //   }, 1500)
      // }
      setFoods(food);
    }
  };

  const addDishToCalories = async () => {
    if (!grams) {
      setErrorGrams(true);
      setTimeout(() => {
        setErrorGrams(false);
      }, 1500);
      return;
    }

    if (foods.length > 0 && userId) {
      await dispatch(addDishFunction(userId, foods[0], grams, query));
      setQuery("");
      setFoods([]);
      setMsgShow(true);
      setTimeout(() => {
        setMsgShow(false);
      }, 3000);
    }
  };

  return (
    <>
      <div className="w-4/5 max-w-md mx-auto p-4 ">
        <div className="flex">
          <input
            type="text"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            placeholder="הקלד/י מאכל אחד לחיפוש"
            className="flex-grow mr-2 border border-black rounded-r-xl px-4 py-2 "
          />
          <button
            onClick={handleSearch}
            className="bg-black  hover:text-xl text-myGreen font-semibold py-2 px-4 rounded-l-xl"
          >
            חפש
          </button>
        </div>
        <div></div>
        <div>
          {error && (
            <div className="text-white h-12 bg-red-700 flex  justify-center rounded-xl p-2  mt-3 ">
              <h1 className=" mt-2">
                המנה {query} לא נמצאה, ניתן להוסיף ידנית
              </h1>
            </div>
          )}
          {errorGrams && (
            <div className="text-white h-12 bg-red-700 flex  justify-center rounded-xl  mt-3 ">
              <h1 className=" mt-2">נא להכניס בגרמים את משקל המנה</h1>
            </div>
          )}
          {foods.map((food) => (
            <div key={food.name} className="my-4  ">
              <div>
                <FoodItem key={food.name} food={food} dishName={query} />
              </div>

              <div>
                {foods.length > 0 && (
                  <div className=" flex mt-2 mb-40">
                    <button
                      onClick={addDishToCalories}
                      className="bg-black border w-1/2 border-black text-myGreen hover:bg-myGreen hover:text-black  font-semibold py-2 px-4 rounded-r-xl"
                    >
                      הוסף מנה
                    </button>
                    <input
                      type="number"
                      placeholder="כמות (בגרם)"
                      className="border border-black rounded-l-xl px-4 py-2  w-1/2"
                      onChange={(e) => setGrams(e.target.value)}
                    />
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
        {msgShow && (
          <div className="text-white h-12 bg-myGreen flex  justify-center rounded-xl  mt-3 ">
            <h1 className=" mt-2"> מנה זו הוספה בהצלחה</h1>
          </div>
        )}
        <div>
          <UserDishes />
        </div>

        <div className=" w-full mx-auto bg-black flex  rounded-b-2xl justify-center p-2 text-myGreen  border-2 border-black  mb-96">
          <Link
            to="/Add_user_dish"
            className=" w-full hover:text-myGreen text-white flex justify-center"
          >
            <h1>לחץ להוספת מנה ידנית</h1>
            <BiMessageSquareAdd className=" mr-2 mt-1" />
          </Link>
        </div>
      </div>
    </>
  );
};

export default AddDishPage;
