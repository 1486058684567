import axios from 'axios'

export const searchFood = async (query) => {
  console.log(query)
  try {
    const options = {
      method: 'GET',
      url: 'https://nutrition-by-api-ninjas.p.rapidapi.com/v1/nutrition',
      params: {
        query,
      },
      headers: {
        'X-RapidAPI-Key': process.env.REACT_APP_RAPIDAPI_KEY,
        'X-RapidAPI-Host': 'nutrition-by-api-ninjas.p.rapidapi.com',
      },
    }

    const response = await axios.request(options)
    console.log(response.data)
    return response.data
  } catch (error) {
    console.error(error)
  }
}

export const getDailyIntakeByDate = async (userId) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/daily_intake/${userId}`
    )
    return response.data
  } catch (error) {
    console.error('Error fetching calories:', error)
    throw error
  }
}
