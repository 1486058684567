import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteDishOfUser } from '../actions/userActions'
import { addDishFunction } from '../actions/caloriesActions'
import { GiHotMeal } from 'react-icons/gi'

const UserDishes = () => {
  const dispatch = useDispatch()
  const { userInfo } = useSelector((state) => state.userLogin)
  const dishes = useSelector((state) => state.userDishes.dishes)
  const [gramsValues, setGramsValues] = useState([])
  const [gramsError, setGramsError] = useState(false)

  const [msgShow, setMsgShow] = useState(false)
  const userId = userInfo ? userInfo._id : null

  useEffect(() => {
    console.log(dishes)

    if (dishes && dishes.length > 0) {
      setGramsValues(Array(dishes.length).fill(''))
    }
  }, [dishes])

  const handleGramsChange = (index, value) => {
    const updatedGramsValues = [...gramsValues]
    updatedGramsValues[index] = value
    setGramsValues(updatedGramsValues)
  }

  const addDishToCalories = async (query, gram, dish) => {
    if (gram === '') {
      setGramsError(true)
      setTimeout(() => {
        setGramsError(false)
      }, 1500)
      return
    }

    await dispatch(addDishFunction(userId, dish, gram, query))
    setMsgShow(true)
    setTimeout(() => {
      setMsgShow(false)
    }, 3000)
  }

  const deleteFunction = async (dishId) => {
    await dispatch(deleteDishOfUser(dishId))
  }

  if (dishes === undefined || dishes?.length === 0) {
    console.log(dishes)
    return (
      <div className=' w-full mt-8 py-4 border-t-1 bg-red-700 rounded-t-lg    '>
        <h1 className='w-2/3 mx-auto flex justify-center  p-2 text-white'>
          ליוזר זה אין מנות ידניות
        </h1>
      </div>
    )
  }
  return (
    <div className='mx-auto max-w-md  mt-10  '>
      <div className='w-full  p-1  bg-white border border-black rounded-t-lg shadow sm:p-8   mt-1'>
        <div className=' w-2/3 mx-auto flex justify-center '>
          <div>
            <GiHotMeal className='mt-3 ml-4 text-lg' />
          </div>
          <div>
            <h1 className='my-2  text-lg mx-auto'> בחירה מהמנות שלי </h1>
          </div>

          <div>
            <GiHotMeal className='mt-3 mr-4 text-lg' />
          </div>
        </div>

        {dishes?.length > 0 &&
          dishes.map((dish, index) => (
            <div key={index}>
              <div className=' w-full flex p-2  border-t-2'>
                <div className='w-2/5 '>{dish.dishName}</div>
                <div className='w-1/5 '>
                  <input
                    className='w-full'
                    type='number'
                    placeholder='גרם'
                    value={gramsValues[index]}
                    onChange={(e) => handleGramsChange(index, e.target.value)}
                  />
                </div>
                <div className='w-1/5  border-x-2'>
                  <button
                    className='mr-3 hover:bg-myGreen hover:px-2 hover:rounded-lg hover:pl-1'
                    onClick={() =>
                      addDishToCalories(
                        dish.dishName,
                        gramsValues[index],
                        dish,
                        index
                      )
                    }
                  >
                    הוסף
                  </button>
                </div>
                <div className='w-1/5 '>
                  <button
                    className='mr-3 hover:bg-red-700 hover:px-2 hover:rounded-lg hover:pl-1'
                    onClick={() => {
                      console.log('Button clicked')
                      deleteFunction(dish._id)
                    }}
                  >
                    מחק
                  </button>
                </div>
              </div>
            </div>
          ))}
        {msgShow && (
          <div className='text-white h-10 bg-myGreen flex  justify-center rounded-xl  mt-3 '>
            <h1 className=' mt-2'> מנה זו הוספה בהצלחה</h1>
          </div>
        )}
        {gramsError && (
          <div className='text-white h-10 bg-red-700 flex  justify-center rounded-xl  mt-3 '>
            <h1 className=' mt-2'>נא להכניס בגרמים את משקל המנה</h1>
          </div>
        )}
      </div>
    </div>
  )
}

export default UserDishes
