import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { logout } from '../actions/userActions'
import { useSelector, useDispatch } from 'react-redux'
import { BarLoader } from 'react-spinners'
const Navbar = () => {
  const dispatch = useDispatch()
  const serverStatus = useSelector((state) => state.server.isServerAwake)

  const handleLogout = () => {
    dispatch(logout())
  }

  return (
    <>
      <nav className='border-b bg-black mx-auto mt-6 rounded-xl w-5/6 font-sans min-h-36 m-0 shadow h-[60px]'>
        <div className='flex items-center justify-between mx-auto w-5/6 flex-row-reverse'>
          <Link
            to='/'
            onClick={handleLogout}
            className='font-playfair border-1 border-myGreen text-xl font-bold text-black px-3 py-1 mt-3 rounded-xl bg-white'
          >
            <h1>Take care</h1>
          </Link>

          <div className='flex justify-between mt-4  font-opensans text-sm font-semibold'>
            <div className='border  border-myGreen rounded-xl px-4 hover:bg-myGreen hover:text-white'>
              <Link
                to='/my_account'
                className='text-white text-lg font-semibold '
              >
                איזור אישי
              </Link>
            </div>
          </div>
        </div>
      </nav>
      <div className=' '>
        {serverStatus === null && (
          <div className=' max-h-8 px-2 rounded w-5/6 mx-auto  mt-2 mt-30'>
            <div className=' w-1/3 mx-auto'>
              <div className=' w-3/4 mx-auto lg:w-1/4 '>
                <h1 className=' text-red-500 mb-1 mr-2 '> מתחבר לשרת</h1>
                <BarLoader color='red' height='2' />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default Navbar
