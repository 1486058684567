import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { updateUserProfile } from '../actions/userActions'
import { RiLockPasswordFill } from 'react-icons/ri'
import { GiMuscleUp } from 'react-icons/gi'
import Nav from '../components/Nav'
import Account from '../components/Account'
import WorkoutPlan from '../components/WorkoutPlan'

const MyAccount = () => {
  const [isAccount, setIsAccount] = useState(false)
  return (
    <>
      <Nav />
      {isAccount ? <WorkoutPlan /> : <Account />}
      <div>
        <button
          onClick={() => setIsAccount((prev) => !prev)}
          className='flex bg-gray-100  sm:max-w-xl  w-5/6 py-2 border-t-gray-100 rounded-b-xl mx-auto hover:border-2 hover:bg-gray-300  border-black border-2 justify-center '
        >
          {isAccount ? (
            <h1 className='text-black'> עבור לשינוי סיסמה</h1>
          ) : (
            <h1 className='text-black'>עבור לשינוי תוכנית תזונה</h1>
          )}
          {isAccount ? (
            <RiLockPasswordFill className=' mt-1 mr-3' />
          ) : (
            <GiMuscleUp className=' mt-1 mr-3' />
          )}
        </button>
      </div>
    </>
  )
}

export default MyAccount
