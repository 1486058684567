import React from 'react'
import { Link } from 'react-router-dom'
import BG from '../svg/bg.svg'
import HEART from '../svg/heart.png'

const HomePage = () => {
  return (
    <>
      <div className=' h-screen pb-14  lg:-mt-32 bg-transparent  '>
        <div className='container  mt-6  md:pt-48 px-6 mx-auto flex flex-wrap flex-col md:flex-row items-center   '>
          <div className='order-3 md:order-1 -mt-16  md:mt-0 bg-cover flex flex-col w-full xl:w-2/5 justify-center lg:items-start overflow-y-hidden '>
            <h1 className='my-4  text-3xl md:text-5xl text-black font-bold leading-tight text-center md:text-left  '>
              <span>Take care </span>{' '}
              <span className='text-white bg-black px-2 py-1 rounded-md'>
                app{' '}
              </span>
            </h1>
            <p className=' leading-tight text-base md:text-2xl mb-4 text-center md:text-left'>
              האפליקציה המושלמת למעקב אחר בריאותך היומית,
            </p>
            <p className=' leading-tight text-base md:text-2xl mb-8 text-center md:text-left'>
              גלה ערכים במאכלים ועקוב אחרי תזונתך היומית.
            </p>

            <div className='flex   w-full justify-center md:justify-start pb-24 lg:pb-0 '>
              <div className='border border-black px-2 py-3 rounded-3xl bg-white'>
                <Link
                  to={'login'}
                  className='h-12 mt-4 rounded-r-3xl pr-4 border-l-4 border-white bg-myGreen hover:bg-white hover:text-myGreen hover:border hover:border-myGreen hover:ml-2 text-white font-bold py-2 px-4 '
                >
                  התחבר
                </Link>
                <Link
                  to={'CaloriesCalculator'}
                  className='h-12 pr-4 mt-4 rounded-l-3xl bg-myGreen  hover:bg-white hover:text-myGreen hover:border hover:border-myGreen hover:mr-2 text-white font-bold py-2 px-4 '
                >
                  מחשבון קלוריות
                </Link>
              </div>
            </div>
          </div>

          <div className='w-full order-1   rounded-full  md:-mt-16 xl:w-3/5 py-6  max-h-96 flex justify-center'>
            <div>
              <img className='w-5/6 mx-auto  ' src={HEART} alt='heart' />
            </div>
          </div>

          <div className='w-full order-3 md:order-last md:pt-16 pb-6 text-sm text-center md:text-left'>
            <a
              className='text-gray-500 no-underline hover:no-underline'
              href='#'
            >
              &copy; Yigal lipsey
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

export default HomePage
