import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchDailyIntake } from '../actions/caloriesActions'
import CaloriesOfDay from '../components/CaloriesOfDay'
import Navbar from '../components/Navbar'

const Dashboard = () => {
  const [todayIntake, setTodayIntake] = useState([])
  const [selectedDate, setSelectedDate] = useState('')
  const [isTodayIntakeShown, setIsTodayIntakeShown] = useState(true)
  const [isHaveTodayIntake, setIsHaveTodayIntake] = useState(false)
  const [isAnotherDaySelected, setIsAnotherDaySelected] = useState(false)

  const dispatch = useDispatch()
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin
  const dailyIntakeData = useSelector((state) => state.dailyIntake.dailyIntake)

  const ifNotHaveTodayIntake = [
    { calories: 0, carbohydrates: 0, fat: 0, fiber: 0, protein: 0 },
  ]

  useEffect(() => {
    if (userInfo) {
      console.log('a')
      dispatch(fetchDailyIntake(userInfo._id))
    }
  }, [dispatch, userInfo])

  useEffect(() => {
    if (dailyIntakeData) {
      setTodayIntake((prevArray) => [
        ...prevArray,
        dailyIntakeData[dailyIntakeData.length - 1],
      ])
      if (dailyIntakeData.length > 0) {
        const todayDate = new Date().toISOString().substr(0, 10)
        const firstDataDate = dailyIntakeData[
          dailyIntakeData.length - 1
        ].date.substr(0, 10)

        if (firstDataDate === todayDate) {
          setIsHaveTodayIntake(true)
        } else {
          setIsHaveTodayIntake(false)
        }
      }
    }
    console.log(isHaveTodayIntake)
  }, [dailyIntakeData])

  const handleDateChange = (e) => {
    setIsTodayIntakeShown(false)
    setIsAnotherDaySelected(true)

    setSelectedDate(e.target.value)
    if (e.target.value === '') {
      setIsTodayIntakeShown(true)
      setIsAnotherDaySelected(false)
    }
  }

  const getUniqueDates = () => {
    if (!dailyIntakeData) {
      return []
    }

    return [...new Set(dailyIntakeData.map((dataEntry) => dataEntry.date))]
  }

  function formatDate(dateString) {
    const date = new Date(dateString)
    const day = String(date.getDate()).padStart(2, '0')
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const year = date.getFullYear()
    return `${day}/${month}/${year}`
  }

  // Function to get today's date in the format YYYY-MM-DD
  function getTodayDate() {
    const today = new Date()
    const year = today.getFullYear()
    const month = String(today.getMonth() + 1).padStart(2, '0')
    const day = String(today.getDate()).padStart(2, '0')
    return `${year}-${month}-${day}`
  }

  const uniqueDates = getUniqueDates()

  return (
    <>
      <Navbar />

      <div className=' w-full '>
        {/*Display Today's intake if no date is selected*/}
        {selectedDate && todayIntake.length > 0 && (
          <CaloriesOfDay
            data={dailyIntakeData.filter(
              (dataEntry) => dataEntry.date === selectedDate
            )}
          />
        )}
        {/*Display Today's intake if no date is selected*/}
        {todayIntake.length > 0 && isTodayIntakeShown && isHaveTodayIntake && (
          <CaloriesOfDay data={todayIntake} />
        )}
        {/*Display Today's intake if no date is selected*/}
        {!isHaveTodayIntake && !isAnotherDaySelected && (
          <CaloriesOfDay data={ifNotHaveTodayIntake} />
        )}
        {
          <div className='  flex justify-center mt-5'>
            <select
              className=' border  bg-black text-white p-2 rounded-xl w-1/3'
              value={selectedDate}
              onChange={handleDateChange}
            >
              <option value=''>היום </option>
              {uniqueDates.map((date) => (
                <option key={date} value={date}>
                  {formatDate(date)}
                </option>
              ))}
            </select>
          </div>
        }
      </div>
    </>
  )
}

export default Dashboard
