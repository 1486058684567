import axios from "axios";
import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGOUT,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAIL,
  USER_UPDATE_PROFILE_REQUEST,
  USER_UPDATE_PROFILE_SUCCESS,
  USER_UPDATE_PROFILE_FAIL,
  USER_STORE_DISHES,
  ADD_DISH_OF_USER_FAIL,
  ADD_DISH_OF_USER_SUCCESS,
  ADD_DISH_OF_USER_REQUEST,
  DELETE_DISH_OF_USER_SUCCESS,
  SET_DISHES_OF_USER,
  RESET_SUCCESS,
} from "../constants/userConstants";

//Login user
export const login = (email, password) => async (dispatch) => {
  try {
    dispatch({
      type: USER_LOGIN_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/users/login`,
      { email, password },
      config
    );
    console.log(data);

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    });

    dispatch({
      type: USER_STORE_DISHES,
      payload: data.userDishes,
    });
    dispatch({
      type: SET_DISHES_OF_USER,
      payload: data.userDishes,
    });

    console.log(data.userDishes);
    localStorage.setItem("userDishes", JSON.stringify(data.userDishes));
    localStorage.setItem("userInfo", JSON.stringify(data));
  } catch (err) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

//Register user
export const register =
  (
    email,
    password,
    age,
    weight,
    height,
    gender,
    fatLossGoal,
    weeklyFatLossRate,
    activityLevel
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: USER_REGISTER_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/users`,
        {
          email,
          password,
          age,
          weight,
          height,
          gender,
          fatLossGoal,
          numOfWeeks: weeklyFatLossRate,
          activityLevel,
        },
        config
      );
      console.log(data);
      dispatch({
        type: USER_REGISTER_SUCCESS,
        payload: data,
      });

      dispatch({
        type: USER_LOGIN_SUCCESS,
        payload: data,
      });

      localStorage.setItem("userInfo", JSON.stringify(data));
    } catch (err) {
      dispatch({
        type: USER_REGISTER_FAIL,
        payload:
          err.response && err.response.data.message
            ? err.response.data.message
            : err.message,
      });
    }
  };

export const updateUserProfile = (user) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_UPDATE_PROFILE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(
      `${process.env.REACT_APP_BASE_URL}/users/profile`,
      user,
      config
    );

    console.log(data);

    dispatch({
      type: USER_UPDATE_PROFILE_SUCCESS,
      payload: data,
    });

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    });

    console.log(data.userDishes);

    localStorage.setItem("userDishes", JSON.stringify(data.userDishes));
    localStorage.setItem("userInfo", JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: USER_UPDATE_PROFILE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const logout = () => (dispatch) => {
  console.log("logout action");
  localStorage.removeItem("userInfo");
  localStorage.removeItem("userDishes");

  dispatch({ type: USER_LOGOUT });
};

//Add dish to user's special dishes

export const addDishOfUser = (dishData) => async (dispatch, getState) => {
  console.log("added");
  try {
    dispatch({ type: ADD_DISH_OF_USER_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/users/add-dish`,
      dishData,
      config
    );
    console.log(data.updatedUser.userDishes);

    dispatch({
      type: ADD_DISH_OF_USER_SUCCESS,
      payload: data.updatedUser.userDishes,
    });
    console.log(data);

    localStorage.setItem(
      "userDishes",
      JSON.stringify(data.updatedUser.userDishes)
    );
  } catch (error) {
    dispatch({
      type: ADD_DISH_OF_USER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const resetSuccess = () => ({
  type: RESET_SUCCESS,
});

export const deleteDishOfUser = (dishId) => async (dispatch) => {
  console.log("active");
  // Retrieve user data from local storage
  const userDataString = localStorage.getItem("userInfo");

  if (!userDataString) {
    console.error("User data not found in local storage");
    return;
  }

  try {
    const userData = JSON.parse(userDataString);

    const userToken = userData.token;

    // Make the DELETE request with the user's token

    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/users/deleteDish/${dishId}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status === 200) {
      const responseData = await response.json();
      console.log(responseData.updatedUser.userDishes);
      dispatch({
        type: DELETE_DISH_OF_USER_SUCCESS,
        payload: responseData.updatedUser.userDishes,
      });

      // Update local storage with the new userDishes data
      localStorage.setItem(
        "userDishes",
        JSON.stringify(responseData.updatedUser.userDishes)
      );
    } else {
      console.error("Failed to delete dish");
    }
  } catch (error) {
    console.error("An error occurred", error);
  }
};
