// serverReducer.js
import {
  START_SERVER_CHECK,
  STOP_SERVER_CHECK,
} from '../constants/serverConstans'

const initialState = {
  isServerAwake: null,
}

const serverReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_SERVER_CHECK:
      return {
        ...state,
        isServerAwake: null,
      }
    case STOP_SERVER_CHECK:
      console.log(action.payload)
      return {
        ...state,
        isServerAwake: action.payload,
      }
    default:
      return state
  }
}

export default serverReducer
