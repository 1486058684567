import axios from 'axios'

import {
  ADD_DISH_REQUEST,
  ADD_DISH_SUCCESS,
  ADD_DISH_FAIL,
  FETCH_DAILY_INTAKE_REQUEST,
  FETCH_DAILY_INTAKE_SUCCESS,
  FETCH_DAILY_INTAKE_FAIL,
} from '../constants/caloriesConstants'

// Action creator for adding a dish to the user's calorie intake
export const addDishFunction =
  (userId, dishData, grams, query) => async (dispatch) => {
    try {
      dispatch({
        type: ADD_DISH_REQUEST,
      })

      // Calculate the values per gram
      const caloriesPerGram = dishData.calories / dishData.serving_size_g
      const proteinPerGram = dishData.protein_g / dishData.serving_size_g
      const carbohydratesPerGram =
        dishData.carbohydrates_total_g / dishData.serving_size_g
      const fatPerGram = dishData.fat_total_g / dishData.serving_size_g
      const fiberPerGram = dishData.fiber_g / dishData.serving_size_g

      // Calculate the values for the user's entered grams
      const calories = Math.floor(grams * caloriesPerGram)
      const protein = Math.floor(grams * proteinPerGram)
      const carbohydrates = Math.floor(grams * carbohydratesPerGram)
      const fat = Math.floor(grams * fatPerGram)
      const fiber = Math.floor(grams * fiberPerGram)

      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      }

      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/daily_intake/update/${userId}`,
        {
          calories,
          protein,
          carbohydrates,
          fat,
          fiber,
          grams,
          dishName: query,
        },
        config
      )

      dispatch({
        type: ADD_DISH_SUCCESS,
        payload: data,
      })

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/daily_intake/${userId}`
      )

      const { dataAfterUpdate } = response

      dispatch({
        type: FETCH_DAILY_INTAKE_SUCCESS,
        payload: dataAfterUpdate,
      })
    } catch (err) {
      dispatch({
        type: ADD_DISH_FAIL,
        payload:
          err.response && err.response.data.message
            ? err.response.data.message
            : err.message,
      })
    }
  }

export const fetchDailyIntake = (userId) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_DAILY_INTAKE_REQUEST })

    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/daily_intake/${userId}`
    )
    const { data } = response

    // console.log(data)
    dispatch({
      type: FETCH_DAILY_INTAKE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: FETCH_DAILY_INTAKE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : 'Error fetching daily intake',
    })
  }
}
