import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'

const DishesDisplayPage = () => {
  const [consumedDishes, setConsumedDishes] = useState([])
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const userId = userInfo._id

  useEffect(() => {
    if (userInfo) {
      const fetchConsumedDishes = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/daily_intake/consumed/${userId}`
          )
          console.log(response.data.consumedDishes)
          setConsumedDishes(response.data.consumedDishes)
        } catch (error) {
          console.error('Error fetching consumed dishes:', error)
        }
      }

      fetchConsumedDishes()
    }
  }, [userInfo])

  if (consumedDishes.length == 0) {
    return (
      <div className=' w-3/4 mx-auto mt-8 py-4 border-t-1 bg-red-700 rounded-lg  mb-96  '>
        <h1 className=' mx-auto flex justify-center  p-2 text-white'>
          עוד לא צרכת מנות להיום
        </h1>
      </div>
    )
  }

  return (
    <div>
      <div className='w-3/4 mx-auto max-w-md p-4 bg-white border border-black rounded-lg shadow sm:p-8  '>
        <h1 className='text-xl font-bold text-gray-900 '>
          המנות שאכלתי היום :
        </h1>
        <ul>
          {consumedDishes.map((dish) => (
            <li key={dish._id} className='py-3 sm:py-4'>
              <div className='flex items-center space-x-4'>
                <div className='flex-shrink-0'></div>
                <div className='flex-1 min-w-0'>
                  <p className=' font-medium text-gray-900 text-l truncate '>
                    {dish.dishName}
                  </p>
                </div>
                <div className='inline-flex items-center text-l font-semibold  text-gray-900 '>
                  g {dish.grams}
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default DishesDisplayPage
