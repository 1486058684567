import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { updateUserProfile } from '../actions/userActions'

const Account = () => {
  const dispatch = useDispatch()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [message, setMessage] = useState(null)

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const userProfileUpdate = useSelector((state) => state.userProfileUpdate)
  const { success } = userProfileUpdate

  useEffect(() => {
    if (userInfo) {
      setEmail(userInfo.email)
    }
  }, [userInfo])

  const submitHandler = (e) => {
    e.preventDefault()
    if (password === '') {
      setMessage(' חובה להקליד סיסמא חדשה')
    }
    if (password !== confirmPassword) {
      setMessage(' סיסמאות אינן תואמות')
    }
    if (password !== ' ' && confirmPassword !== '') {
      setMessage('')
      dispatch(updateUserProfile({ id: userInfo._id, email, password }))
    }
  }
  return (
    <>
      <form className=' min-w-max  mt-12  ' onSubmit={submitHandler}>
        <div className=''>
          <div className='relative  sm:max-w-xl sm:mx-auto w-5/6 mx-auto   '>
            <div className='relative px-4 py-10 bg-gray-100 shadow-lg rounded-t-xl sm:p-20 border-2 border-black'>
              <div className='max-w-md mx-auto'>
                <div>
                  <h1 className=' text-2xl'>החשבון שלי </h1>
                </div>
                <div className='divide-y divide-gray-200'>
                  <div className='py-4 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7'>
                    <div className='relative'>
                      <label htmlFor='password'>כתובת מייל</label>
                      <input
                        className={`peer h-7 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none `}
                        type='email'
                        id='passwemailord'
                        value={email}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                    <div className='relative'>
                      <label htmlFor='password'>סיסמה</label>
                      <input
                        className={`peer h-7 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none `}
                        type='password'
                        id='password'
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                    <div className='relative'>
                      <label htmlFor='confirmPassword'>אשר סיסמה</label>
                      <input
                        className={`peer h-7 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none `}
                        type='password'
                        id='confirmPassword'
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                      />
                    </div>
                    <div className='relative'>
                      <button className='bg-black text-white rounded-md px-2 py-1 hover:border-black hover:border hover:text-black hover:bg-gray-100'>
                        שמור שינויים
                      </button>
                    </div>
                    {message && (
                      <div className='text-red-500 text-center mt-4'>
                        {message}
                      </div>
                    )}
                    {success && (
                      <div className='text-white h-12 bg-myGreen flex  justify-center rounded-xl  '>
                        <h1 className=' mt-2'> פרופיל עודכן בהצלחה</h1>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}

export default Account
