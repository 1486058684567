import axios from 'axios'

export const translateText = async (text) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/translate`,
      {
        text,
      }
    )
    // console.log(response.data.translation)
    return response.data.translation
  } catch (error) {
    console.error(error)
  }
}
