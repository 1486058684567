import {
  FETCH_DAILY_INTAKE_REQUEST,
  FETCH_DAILY_INTAKE_SUCCESS,
  FETCH_DAILY_INTAKE_FAIL,
} from '../constants/caloriesConstants'

const initialState = {
  loading: false,
  dailyIntake: null,
  error: null,
}

const dailyIntakeReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DAILY_INTAKE_REQUEST:
      return { ...state, loading: true }
    case FETCH_DAILY_INTAKE_SUCCESS:
      return {
        ...state,
        loading: false,
        dailyIntake: action.payload,
        success: true,
      }
    case FETCH_DAILY_INTAKE_FAIL:
      return { ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

export default dailyIntakeReducer
