// routes.js
import React from 'react'
import { Routes, Route } from 'react-router-dom'
import HomePage from './pages/HomePage'
import CheckCaloriesCalculator from './pages/CheckCaloriesCalculator'
import Login from './pages/Login'
import Register from './pages/Register'
import Dashboard from './pages/Dashboard'
import MyAccount from './pages/MyAcount'
import AddUserDish from './pages/AddUserDish'

const MyRoutes = () => (
  <Routes>
    <Route path='/' element={<HomePage />} />
    <Route path='CaloriesCalculator' element={<CheckCaloriesCalculator />} />
    <Route path='Login' element={<Login />} />
    <Route path='Register' element={<Register />} />
    <Route path='Dashboard' element={<Dashboard />} />
    <Route path='My_account' element={<MyAccount />} />
    <Route path='Add_user_dish' element={<AddUserDish />} />
  </Routes>
)

export default MyRoutes
