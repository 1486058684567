import React, { useState, useEffect } from 'react'
import { translateText } from '../apis/translateApi'
import { searchFood } from '../apis/dishesApi'
import FoodItem from '../components/FoodItem'
import { BsCalculatorFill } from 'react-icons/bs'

const CheckCaloriesCalculator = () => {
  const [query, setQuery] = useState('')
  const [foods, setFoods] = useState([])
  const [error, setError] = useState(false)

  const handleSearch = async () => {
    const translation = await translateText(query)
    if (translation) {
      const food = await searchFood(translation)
      if (food.length == 0) {
        setError(true)
        setTimeout(() => {
          setError(false)
        }, 1500)
      }
      setFoods(food)
    }
  }

  useEffect(() => {
    console.log(foods[0])
  }, [foods])

  return (
    <div className='w-4/5 max-w-md mx-auto p-4 '>
      <div className='w-full justify-center flex'>
        <h1 className='text-2xl font-bold mb-4 '>מחשבון קלורי במנות</h1>
        <BsCalculatorFill className=' mr-3 mt-2 text-xl' />
      </div>

      <div className='flex'>
        <input
          type='text'
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          placeholder='הקלד שם מאכל '
          className='flex-grow mr-2 border border-black rounded-r-xl px-4 py-2 '
        />
        <button
          onClick={handleSearch}
          className='bg-black  hover:text-xl text-myGreen font-semibold py-2 px-4 rounded-l-xl'
        >
          חפש
        </button>
      </div>
      <div className='mt-6'>
        {foods.map((food) => (
          <FoodItem key={food.name} food={food} dishName={query} />
        ))}
      </div>
      {error && (
        <div className='text-white h-12 bg-red-700 flex  justify-center rounded-xl p-2  mt-3 '>
          <h1 className=' mt-2'>המנה {query} לא נמצאה, </h1>
        </div>
      )}
    </div>
  )
}

export default CheckCaloriesCalculator
