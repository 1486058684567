import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./Routes";
import BackgroundImage from "./svg/fdfd.jpeg";
import { useDispatch } from "react-redux";
import { startServerCheck, stopServerCheck } from "./actions/serverActions";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    console.log("Up !");
    const checkServerStatus = async () => {
      dispatch(startServerCheck());

      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/users/ping`
        );

        const data = await response.text();

        if (data !== null) {
          console.log("shone");
          dispatch(stopServerCheck("somthing"));
        } else {
          dispatch(stopServerCheck(false));
        }
      } catch (error) {
        console.error("Error checking server status:", error);
        dispatch(stopServerCheck(false));
      }
    };

    // Check server status when the component mounts
    checkServerStatus();

    // Set an interval to check server status every 15 minutes
    const intervalId = setInterval(checkServerStatus, 10 * 60 * 1000); // Every 10 minutes

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [dispatch]);

  return (
    <div
      style={{ backgroundImage: `url(${BackgroundImage})` }}
      className="bg-cover min-h-screen pt-2"
    >
      <Router>
        <Routes />
      </Router>
    </div>
  );
}

export default App;
