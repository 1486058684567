import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { updateUserProfile } from '../actions/userActions'

const WorkoutPlan = () => {
  const dispatch = useDispatch()

  const [weight, setWeight] = useState(0)
  const [height, setHeight] = useState(0)
  const [age, setAge] = useState(0)
  const [fatLossGoal, setFatLossGoal] = useState(0)
  const [activityLevel, setActivityLevel] = useState('')
  const [numOfWeeks, setNumOfWeeks] = useState(0)
  const [message, setMessage] = useState('')

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const userProfileUpdate = useSelector((state) => state.userProfileUpdate)
  const { success } = userProfileUpdate

  useEffect(() => {
    if (userInfo) {
      setAge(userInfo.age)
      setHeight(userInfo.height)
      setWeight(userInfo.weight)
      setActivityLevel(userInfo.activityLevel)
      setFatLossGoal(userInfo.fatLossGoal)
      setNumOfWeeks(userInfo.numOfWeeks)
    }
  }, [userInfo])

  const submitHandler = (e) => {
    e.preventDefault()

    dispatch(
      updateUserProfile({
        id: userInfo._id,
        weight,
        height,
        age,
        numOfWeeks,
        fatLossGoal,
        gender: userInfo.gender,
        activityLevel,
      })
    )
    if (success) {
      console.log('success')
    }
  }
  return (
    <>
      <form className='   mt-12  ' onSubmit={submitHandler}>
        <div className=''>
          <div className='relative  sm:max-w-xl sm:mx-auto w-5/6 mx-auto   '>
            <div className='relative px-4 py-6 bg-gray-100 shadow-lg rounded-t-xl sm:p-20 border-2 border-black'>
              <div className='max-w-md mx-auto'>
                <div>
                  <h1>התוכנית שלי</h1>
                </div>
                <div className='divide-y divide-gray-200'>
                  <div className='py-4 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7'>
                    <div className='relative'>
                      <label htmlFor='age'>גיל </label>
                      <input
                        className={`peer h-7 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none `}
                        type='number'
                        id='age'
                        value={age}
                        onChange={(e) => setAge(e.target.value)}
                      />
                    </div>
                    <div className='relative'>
                      <label htmlFor='weight'>משקל</label>
                      <input
                        className={`peer h-7 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none `}
                        type='number'
                        id='weight'
                        value={weight}
                        onChange={(e) => setWeight(e.target.value)}
                      />
                    </div>
                    <div className='relative'>
                      <label htmlFor='height'>גובה</label>
                      <input
                        className={`peer h-7 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none `}
                        type='number'
                        id='height'
                        value={height}
                        onChange={(e) => setHeight(e.target.value)}
                      />
                    </div>
                    <div className='relative'>
                      <label htmlFor='fatLossGoal'>משקל להורדה בתוכנית </label>
                      <input
                        className={`peer h-7 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none `}
                        type='number'
                        id='fatLossGoal'
                        value={fatLossGoal}
                        onChange={(e) => setFatLossGoal(e.target.value)}
                      />
                    </div>
                    <div className='relative'>
                      <label htmlFor='numOfWeeks'>
                        {' '}
                        מספר שבועות להשקעה בתוכנית{' '}
                      </label>
                      <input
                        className={`peer h-7 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none `}
                        type='number'
                        id='numOfWeeks'
                        value={numOfWeeks}
                        onChange={(e) => setNumOfWeeks(e.target.value)}
                      />
                    </div>
                    <div className='relative'>
                      <select
                        onChange={(e) => setActivityLevel(e.target.value)}
                        className={` peer h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:border-blue-700`}
                      >
                        <option value={activityLevel}>
                          תדירות פעילות גופנית
                        </option>
                        <option value='low'>נמוכה</option>
                        <option value='moderate'>בינונית</option>
                        <option value='high'>גבוהה</option>
                      </select>
                    </div>

                    <div className='relative'>
                      <button className='bg-black text-white rounded-md px-2 py-1 hover:border-black hover:border hover:text-black hover:bg-gray-100'>
                        שמור שינויים
                      </button>
                    </div>
                    {message && (
                      <div className='text-red-500 text-center mt-4'>
                        {message}
                      </div>
                    )}
                    {success && (
                      <div className='text-white h-12 bg-myGreen flex  justify-center rounded-xl  '>
                        <h1 className=' mt-2'> פרופיל עודכן בהצלחה</h1>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}

export default WorkoutPlan
