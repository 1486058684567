import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import thunk from "redux-thunk";
import {
  userLoginReducer,
  userLogoutReducer,
  userRegisterReducer,
  userProfileUpdateReducer,
  userDishesReducer,
} from "./reducers/userReducers";
import dailyIntakeReducer from "./reducers/caloriesReducers";
import serverReducer from "./reducers/serverReducers";

// Create the Redux reducer
const reducer = combineReducers({
  userLogin: userLoginReducer,
  userLogout: userLogoutReducer,
  userRegister: userRegisterReducer,
  userProfileUpdate: userProfileUpdateReducer,
  dailyIntake: dailyIntakeReducer,
  userDishes: userDishesReducer,
  server: serverReducer,
});

const initialState = {
  userLogin: {
    userInfo:
      localStorage.getItem("userInfo") === undefined
        ? null
        : JSON.parse(localStorage.getItem("userInfo")),
  },
  userDishes: {
    dishes:
      localStorage.getItem("userDishes") === undefined
        ? null
        : JSON.parse(localStorage.getItem("userDishes")),
  },
};

const store = configureStore({
  reducer,
  preloadedState: initialState,
  middleware: [thunk],
});

export default store;
