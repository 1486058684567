import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { PulseLoader } from "react-spinners";
import { login } from "../actions/userActions";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const userLogin = useSelector((state) => state.userLogin);
  const { error, userInfo, loading } = userLogin;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    // console.log(userInfo)
    if (userInfo) {
      navigate("/Dashboard");
    }

    if (error === "user not registerd") {
      navigate("/register");
    }
  }, [userInfo]);

  const submitHandler = (e) => {
    if (email.trim() === "") {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
    if (password.trim() === "") {
      setPasswordError(true);
    } else {
      setPasswordError(false);
    }
    e.preventDefault();
    dispatch(login(email, password));
  };

  return (
    <>
      <form className="min-h-screen min-w-max  " onSubmit={submitHandler}>
        <div className="py-6 sm:py-12">
          <div className="relative py-3 sm:max-w-xl sm:mx-auto w-5/6 mx-auto mt-16  ">
            <div className="relative px-4 py-10 bg-gray-100 shadow-lg rounded-3xl sm:p-20 border border-black">
              <div className="max-w-md mx-auto">
                <div>
                  <h1 className="text-2xl font-semibold">הכנס אימייל וסיסמא</h1>
                </div>
                <div className="divide-y divide-gray-200">
                  <div className="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
                    <div className="relative">
                      <input
                        autoComplete="off"
                        onChange={(e) => setEmail(e.target.value)}
                        id="email"
                        name="email"
                        type="email"
                        className={`peer h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none ${
                          emailError
                            ? "border-red-700"
                            : "focus:border-green-600"
                        }`}
                        placeholder="כתובת מייל"
                      />
                    </div>
                    <div className="relative">
                      <input
                        autoComplete="off"
                        onChange={(e) => setPassword(e.target.value)}
                        type="password"
                        className={`peer h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none ${
                          passwordError
                            ? "border-red-700"
                            : "focus:border-green-600"
                        }`}
                        placeholder="סיסמה"
                      />
                    </div>
                    <div className="relative">
                      <div className=" flex ">
                        <div className=" w-1/5 ">
                          <button className="bg-black text-white rounded-md px-2 py-1  hover:px-3">
                            {loading ? (
                              <PulseLoader size={8} color="white" margin={3} />
                            ) : (
                              "היכנס"
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="mt-4 text-center">
                      <p>
                        אין לך חשבון?{" "}
                        <Link to="/register" className="text-blue-500">
                          הירשם כאן
                        </Link>
                      </p>
                    </div>
                    {error && (
                      <div className="text-red-500 text-center mt-4">
                        {error} {/* Display the error message */} !
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default Login;
