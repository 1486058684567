export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL'
export const USER_LOGOUT = 'USER_LOGOUT'

export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST'
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS'
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL'

export const USER_DETAILS_REQUEST = 'USER_DETAILS_REQUEST'
export const USER_DETAILS_SUCCESS = 'USER_DETAILS_SUCCESS'
export const USER_DETAILS_FAIL = 'USER_DETAILS_FAIL'

export const USER_UPDATE_PROFILE_REQUEST = 'USER_UPDATE_PROFILE_REQUEST'
export const USER_UPDATE_PROFILE_SUCCESS = 'USER_UPDATE_PROFILE_SUCCESS'
export const USER_UPDATE_PROFILE_FAIL = 'USER_UPDATE_PROFILE_FAIL'
export const USER_UPDATE_PROFILE_RESET = 'USER_UPDATE_PROFILE_RESET'

export const USER_LIST_REQUEST = 'USER_LIST_REQUEST'
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS'
export const USER_LIST_FAIL = 'USER_LIST_FAIL'
export const USER_LIST_RESET = 'USER_LIST_RESET'

export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST'
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS'
export const USER_UPDATE_FAIL = 'USER_UPDATE_FAIL'
export const USER_UPDATE_RESET = 'USER_UPDATE_RESET'
export const USER_UPDATE_HIDE_MESSAGE = 'USER_UPDATE_HIDE_MESSAGE'

export const USER_DELETE_REQUEST = 'USER_DELETE_REQUEST'
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS'
export const USER_DELETE_FAIL = 'USER_DELETE_FAIL'
export const USER_DELETE_HIDE_MESSAGE = 'USER_DELETE_HIDE_MESSAGE'

export const USER_STORE_DISHES = 'USER_STORE_DISHES'

export const ADD_DISH_OF_USER_SUCCESS = 'ADD_DISH_OF_USER_SUCCESS'
export const ADD_DISH_OF_USER_REQUEST = 'ADD_DISH_OF_USER_REQUEST'
export const ADD_DISH_OF_USER_FAIL = 'ADD_DISH_OF_USER_FAIL'

export const ADD_USER_DISH_LOCALI = 'ADD_USER_DISH_LOCALI'

export const RESET_SUCCESS = 'RESET_SUCCESS'

export const DELETE_DISH_OF_USER_SUCCESS = 'DELETE_DISH_OF_USER_SUCCESS'

export const SET_DISHES_OF_USER = 'SET_DISHES_OF_USER'
