import React from 'react'

const FoodItem = ({ food, dishName }) => {
  return (
    <div className='w-full max-w-md p-4 bg-white border border-black rounded-lg shadow sm:p-8  '>
      <div className='flex items-start  mb-4'>
        <h5 className='text-lg font-bold leading-none text-gray-900 '>
          ערכים קלורים של
        </h5>
        <h5 className='text-lg font-bold leading-none text-gray-900  mr-2'>
          {dishName}
        </h5>
        <h5 className='text-lg font-bold leading-none text-gray-900  mr-2'>
          במאה גרם
        </h5>
      </div>
      <div className='flow-root'>
        <ul role='list' className='divide-y divide-black '>
          <li className='py-3 sm:py-4'>
            <div className='flex items-center space-x-4'>
              <div className='flex-shrink-0'>
                {/* <img class="w-8 h-8 rounded-full" src="/docs/images/people/profile-picture-1.jpg" alt="Neil image"> */}
              </div>
              <div className='flex-1 min-w-0'>
                <p className='text-sm font-medium text-gray-900 truncate '>
                  {'קלוריות'}
                </p>
              </div>
              <div className='inline-flex items-center text-base font-semibold text-gray-900 '>
                {food.calories}
              </div>
            </div>
          </li>
          <li className='py-3 sm:py-4'>
            <div className='flex items-center space-x-4'>
              <div className='flex-shrink-0'>
                {/* <img class="w-8 h-8 rounded-full" src="/docs/images/people/profile-picture-3.jpg" alt="Bonnie image"> */}
              </div>
              <div className='flex-1 min-w-0'>
                <p className='text-sm font-medium text-gray-900 truncate '>
                  {'פחמימות'}
                </p>
              </div>
              <div className='inline-flex items-center text-base font-semibold text-gray-900 '>
                {food.carbohydrates_total_g}
              </div>
            </div>
          </li>
          <li className='py-3 sm:py-4'>
            <div className='flex items-center space-x-4'>
              <div className='flex-shrink-0'>
                {/* <img class="w-8 h-8 rounded-full" src="/docs/images/people/profile-picture-2.jpg" alt="Michael image"> */}
              </div>
              <div className='flex-1 min-w-0'>
                <p className='text-sm font-medium text-gray-900 truncate '>
                  {'כולסטרול'}
                </p>
              </div>
              <div className='inline-flex items-center text-base font-semibold text-gray-900 '>
                {food.cholesterol_mg}
              </div>
            </div>
          </li>
          <li className='py-3 sm:py-4'>
            <div className='flex items-center space-x-4'>
              <div className='flex-shrink-0'>
                {/* <img class="w-8 h-8 rounded-full" src="/docs/images/people/profile-picture-2.jpg" alt="Michael image"> */}
              </div>
              <div className='flex-1 min-w-0'>
                <p className='text-sm font-medium text-gray-900 truncate '>
                  {'חלבון'}
                </p>
              </div>
              <div className='inline-flex items-center text-base font-semibold text-gray-900 '>
                {food.protein_g}
              </div>
            </div>
          </li>

          <li className='pt-3 pb-0 sm:pt-4'>
            <div className='flex items-center space-x-4'>
              <div className='flex-shrink-0'>
                {/* <img class="w-8 h-8 rounded-full" src="/docs/images/people/profile-picture-5.jpg" alt="Thomas image"> */}
              </div>
              <div className='flex-1 min-w-0'>
                <p className='text-sm font-medium text-gray-900 truncate '>
                  {'סיבים תזונתיים'}
                </p>
              </div>
              <div className='inline-flex items-center text-base font-semibold text-gray-900 '>
                {food.fiber_g}
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default FoodItem
