// serverActions.js
import {
  START_SERVER_CHECK,
  STOP_SERVER_CHECK,
} from '../constants/serverConstans'

export const startServerCheck = () => ({
  type: START_SERVER_CHECK,
})

export const stopServerCheck = (payload) => ({
  type: STOP_SERVER_CHECK,
  payload: payload,
})

export const checkServerStatus = () => async (dispatch) => {
  dispatch(startServerCheck())

  try {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/users/ping`)

    const data = await response.text()

    if (data !== null) {
      dispatch(stopServerCheck('somthing'))
    } else {
      dispatch(stopServerCheck(false))
    }
  } catch (error) {
    console.error('Error checking server status:', error)
    dispatch(stopServerCheck(false))
  }
}
