import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { logout } from '../actions/userActions'

const Nav = () => {
  const dispatch = useDispatch()

  const handleLogout = () => {
    dispatch(logout())
  }

  return (
    <nav className='border-b bg-black mx-auto mt-6 rounded-xl w-5/6 font-sans min-h-36 m-0 shadow h-[60px]'>
      <div className='flex items-center justify-between mx-auto w-5/6 flex-row-reverse'>
        <Link
          to='/'
          onClick={handleLogout}
          className='font-playfair border-1 border-yellow text-xl font-bold text-black px-3 py-1 mt-3 rounded-xl bg-white'
        >
          <h1>Take care</h1>
        </Link>

        <div className='flex justify-between mt-4  font-opensans text-sm font-semibold'>
          <div className='border  border-myGreen rounded-xl px-4 hover:bg-myGreen hover:text-white'>
            <Link to='/Dashboard' className='text-white text-lg font-semibold '>
              לוח נתונים
            </Link>
          </div>
          {/* Add more navigation links here */}
        </div>
      </div>
    </nav>
  )
}

export default Nav
