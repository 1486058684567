import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { PulseLoader } from "react-spinners";

import { register } from "../actions/userActions";

import { useNavigate } from "react-router-dom";

const Register = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [age, setAge] = useState("");
  const [weight, setWeight] = useState("");
  const [height, setHeight] = useState("");
  const [gender, setGender] = useState("");
  const [fatLossGoal, setFatLossGoal] = useState("");
  const [weeklyFatLossRate, setWeeklyFatLossRate] = useState("");
  const [activityLevel, setActivityLevel] = useState("");

  //Errors
  const [message, setMessage] = useState(null);
  const [ageError, setAgeError] = useState(false);
  const [weightError, setWeightError] = useState(false);
  const [heightError, setHeightError] = useState(false);
  const [fatLossGoalError, setFatLossGoalError] = useState(false);
  const [weeklyFatLossRateError, setWeeklyFatLossRateError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [exerciseFrequencyError, setExerciseFrequencyError] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userRegister = useSelector((state) => state.userRegister);
  const { loading, error } = userRegister;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    console.log(userInfo);
    if (userInfo) {
      navigate("/Dashboard");
    }
  }, [userInfo, error]);

  const handleSubmit = async (e) => {
    if (age.trim() === "") {
      setAgeError(true);
    } else {
      setAgeError(false);
    }
    if (weight.trim() === "") {
      setWeightError(true);
    } else {
      setWeightError(false);
    }
    if (height.trim() === "") {
      setHeightError(true);
    } else {
      setHeightError(false);
    }

    if (fatLossGoal.trim() === "") {
      setFatLossGoalError(true);
    } else {
      setFatLossGoalError(false);
    }

    if (weeklyFatLossRate.trim() === "") {
      setWeeklyFatLossRateError(true);
    } else {
      setWeeklyFatLossRateError(false);
    }

    if (activityLevel.trim() === "") {
      setExerciseFrequencyError(true);
    } else {
      setExerciseFrequencyError(false);
    }

    if (email.trim() === "") {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
    if (password.trim() === "") {
      setPasswordError(true);
    } else {
      setPasswordError(false);
    }

    // Validate the input fields
    if (
      !email ||
      !password ||
      !age ||
      !weight ||
      !height ||
      !gender ||
      !activityLevel ||
      !fatLossGoal ||
      !weeklyFatLossRate
    ) {
      e.preventDefault();
      setMessage(" נא מלא את כל השדות");
      return;
    }

    e.preventDefault();
    dispatch(
      register(
        email,
        password,
        age,
        weight,
        height,
        gender,
        fatLossGoal,
        weeklyFatLossRate,
        activityLevel
      )
    );
  };

  return (
    <form className="min-h-screen min-w-max" onSubmit={handleSubmit}>
      <div className="">
        <div className="relative py-3 sm:max-w-xl sm:mx-auto w-5/6 mx-auto ">
          <div className="relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-20">
            <div className="max-w-md mx-auto">
              <div className="max-w-md mx-auto">
                <h1 className="text-2xl font-semibold">הכנס נתונים</h1>
              </div>
              <div className="divide-y divide-gray-200">
                <div className="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
                  <div className="relative ">
                    <input
                      autoComplete="off"
                      onChange={(e) => setEmail(e.target.value)}
                      id="email"
                      name="email"
                      type="email"
                      className={`peer h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none ${
                        emailError ? "border-red-700" : "focus:border-green-600"
                      }`}
                      placeholder="כתובת מייל"
                    />
                  </div>
                  <div className="relative">
                    <input
                      autoComplete="off"
                      onChange={(e) => setPassword(e.target.value)}
                      type="password"
                      className={`peer h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none ${
                        passwordError
                          ? "border-red-700"
                          : "focus:border-green-600"
                      }`}
                      placeholder="סיסמה"
                    />
                  </div>
                  <div className="relative">
                    <input
                      autoComplete="off"
                      onChange={(e) => setAge(e.target.value)}
                      type="text"
                      className={`peer ${
                        ageError ? "border-red-700" : ""
                      } h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:border-blue-700`}
                      placeholder="גיל"
                    />
                  </div>
                  <div className={`relative ${weightError ? "error" : ""}`}>
                    <input
                      autoComplete="off"
                      onChange={(e) => setWeight(e.target.value)}
                      type="text"
                      dir="rtl"
                      className={`peer ${
                        weightError ? "border-red-700" : ""
                      } h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:border-blue-700`}
                      placeholder="משקל"
                    />
                  </div>
                  <div className="relative">
                    <input
                      autoComplete="off"
                      onChange={(e) => setHeight(e.target.value)}
                      type="text"
                      className={`peer ${
                        heightError ? "border-red-700" : ""
                      } h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:border-blue-700`}
                      placeholder="גובה"
                    />
                  </div>

                  <div className="relative">
                    <input
                      autoComplete="off"
                      onChange={(e) => setFatLossGoal(e.target.value)}
                      type="text"
                      className={`peer ${
                        fatLossGoalError ? "border-red-700" : ""
                      } h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:border-blue-700`}
                      placeholder='כמה תרצה להוריד ?   (בק"ג)'
                    />
                  </div>
                  <div className="relative">
                    <input
                      autoComplete="off"
                      onChange={(e) => setWeeklyFatLossRate(e.target.value)}
                      type="text"
                      className={`peer ${
                        weeklyFatLossRateError ? "border-red-700" : ""
                      } h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:border-blue-700`}
                      placeholder="רשום את מספר השבועות להשקעה בתוכנית"
                    />
                  </div>
                  <div className="relative">
                    <select
                      onChange={(e) => setGender(e.target.value)}
                      className={` peer h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:border-blue-700`}
                    >
                      <option value="">בחר מין</option>
                      <option value="male">זכר</option>
                      <option value="female">נקבה</option>
                    </select>
                  </div>
                  <div className="relative">
                    <select
                      onChange={(e) => setActivityLevel(e.target.value)}
                      className={` peer h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:border-blue-700`}
                    >
                      <option value="">תדירות פעילות גופנית</option>
                      <option value="low">נמוכה</option>
                      <option value="moderate">בינונית</option>
                      <option value="high">גבוהה</option>
                    </select>
                  </div>

                  <div className="relative">
                    <div className=" flex ">
                      <div className=" w-1/5 ">
                        <button className="bg-black text-white rounded-md px-2 py-1  hover:px-3">
                          {loading ? (
                            <PulseLoader size={8} color="white" margin={3} />
                          ) : (
                            "הרשם"
                          )}
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="mx-auto w-full">
                    {" "}
                    {message && <div className="text-red-500 ">{message}</div>}
                  </div>
                </div>
              </div>
              <div className="mt-4 text-center">
                <p>
                  לקוח קיים?
                  <Link to="/login" className="text-blue-500">
                    כניסה
                  </Link>
                </p>
                {error && (
                  <div className="text-red-500 text-center mt-4">{error}</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default Register;
